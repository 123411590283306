<template>
  <div>
    <v-dialog v-model="dialog" width="850">
      <v-card flat class="">
        <v-card-title class="">
          <h2 class="">Gallery Mobile</h2>
        </v-card-title>
        <v-card-text>
          <div>
            <v-row>
              <v-col
                v-for="photo in project_photos"
                :key="photo.id"
                class="d-flex child-flex"
                cols="6"
              >
                <v-img
                  :src="photo.picture_url"
                  lazy-src="@/assets/jmbcircle.png"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="view_image(photo.picture_url)"
                >
                  <h3 class="white--text pa-2">
                    Title: {{ photo.picture_description }}
                  </h3>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </div>
          <h3 class="mt-4">Upload Picture:</h3>
          <div class="mt-2 mb-10">
            <input
              class="black--text mb-3"
              type="file"
              ref="photo_upload"
              name="photo_upload"
              @change="insert_image"
              accept="image/jpeg"
            />
            <v-text-field
              class="mr-2"
              label="Photo Description"
              v-model="picture_description"
            ></v-text-field>
            <v-btn
              x-small
              class=""
              color="primary"
              @click="upload_image"
              :loading="uploading"
            >
              Upload Picture</v-btn
            >
          </div>

          <div class="d">
            <h1 class="mb-3">
              <!-- <h2 class="success--text"> -->
              {{ problem.problem_description }}
            </h1>
            <h2>Problem ID: {{ problem.id }}</h2>
            <h2 class="mt-1">
              Status:

              <span
                :class="
                  problem.status == 'Pending' ? 'orange--text' : 'green--text'
                "
                >{{ problem.status }}</span
              >
            </h2>
            <h2 class="mt-1">Technician: {{ problem.technician }}</h2>
            <h2 class="mt-1">
              Date Inserted:
              {{ date_inserted }}
            </h2>
          </div>
        </v-card-text>

        <UpdateProblemMobile
          v-if="updateModal"
          :item="problem"
          @update_problem="update_problem"
        />
        <v-card-actions class="d-flex justify-left mt-n7 ml-3">
          <v-btn color="error" class="" @click="$emit('close', true)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>

      <problem-picture-modal
        :picture_url="selected_picture_url"
        :picture_description="picture_description"
        v-if="enable_view_picture"
        @close="enable_view_picture = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import UpdateProblemMobile from "./updateProblemMobile.vue";
import ProblemPictureModal from "./problemPictureModal.vue";
export default {
  components: { UpdateProblemMobile, ProblemPictureModal },
  props: ["problem"],
  data() {
    return {
      selected_picture_url: "",
      enable_view_picture: false,
      uploading: false,
      updateModal: true,
      dialog: true,
      picture: "",
      picture_url: "",
      picture_description: "",
    };
  },
  methods: {
    ...mapActions({
      delete_problem: "problem/delete_problem",
      add_project_photo: "problem/add_project_photo",
      get_project_photos: "problem/get_project_photos",
    }),
    update_problem(data) {
      this.problem = data;
    },
    view_image(image_url) {
      // console.log(image_url);
      this.selected_picture_url = image_url;
      this.enable_view_picture = true;
    },
    insert_image(e) {
      this.picture = e.target.files[0];
      this.picture_url = URL.createObjectURL(this.picture);
    },
    upload_image() {
      // check if empty
      if (this.picture_description == "")
        return alert("Please insert picture description");
      if (this.picture == "")
        return alert("Please insert image before uploading");
      this.uploading = true;
      const storage = getStorage();
      const storageRef = ref(
        storage,
        "TroubleshootingPhotos/" + this.picture_url
      );
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, this.picture).then(() => {
        getDownloadURL(storageRef).then((downloadURL) => {
          // console.log(downloadURL);
          let request = {
            picture_url: downloadURL,
            problem_id: this.problem.id,
            picture_description: this.picture_description,
          };
          this.add_project_photo(request).then(() => {
            alert("Successfully uploaded!");
            this.uploading = false;
          });
        });
      });
    },
    del_problem() {
      let x = window.confirm("Are you sure you want to delete this problem?");
      if (x) {
        this.delete_problem(this.problem.id)
          .then(() => {
            alert("Successfully deleted a problem! PLEASE UPDATE THE TABLE");
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    open_update_modal() {
      this.updateModal = true;
    },
  },
  mounted() {
    // console.log(this.problem);
    this.get_project_photos(this.problem.id);
  },
  computed: {
    ...mapGetters({
      project_photos: "problem/project_photos",
    }),

    date_inserted() {
      var formattedDate = moment(this.problem.created_at).format(
        "MMMM D, YYYY"
      );
      return formattedDate;
      // return this.item.created_at;
    },
  },
};
</script>

<style lang="scss" scoped></style>
