<template>
  <v-dialog v-model="dialog" persistent width="1000">
    <v-card>
      <v-title>
        <d class="d-flex justify-end">
          <v-btn @click="$emit('close')" class="mt-5 mr-5" color="error"
            >Close</v-btn
          ></d
        >
      </v-title>
      <v-card-text>
        <div>
          <br />

          <v-img :src="picture_url" max-width="100%" class="mt-"></v-img>
          <!-- <h2 class="display-1">{{ picture_description }}</h2> -->
        </div>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["picture_url", "picture_description"],
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style></style>
